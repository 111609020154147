import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import "./globalStyles.css";

import Header from "../components/Header";
import Footer from "../components/Footer";

const NotFound = styled.div`
  padding: 0 32px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
  a {
    color: white;
    text-decoration: none;
  }
  .wow {
    font-size: 5rem;
  }
`;

const NotFoundPage = () => {
  return (
    <>
      <Header pageTitle="Page not found"></Header>
      <NotFound>
        <span className="wow">🙊</span>
        <h1>Page Not Found</h1>
        <Link to="/" className="backHome">
          Go home 👉
        </Link>
      </NotFound>
      <Footer></Footer>
    </>
  );
};

export default NotFoundPage;
